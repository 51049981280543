import React from 'react';
import profileImage from '../assets/profile.jpg';
import SocialLinks from './SocialLinks';
import { Typewriter } from 'react-simple-typewriter';
import '../index.css'; // Add this line to include the CSS file

const Home = () => {
    return (
        <section id="home" className="min-h-screen flex flex-col justify-center items-center bg-darkblue text-white">
            <div className="text-center app-container">
                <div className="border-animation mx-auto">
                    <img
                        src={profileImage}
                        alt="Profile"
                        className="profile-image"
                    />
                </div>
                <h2 className="text-4xl font-bold mt-4">
                    Hi, I am{' '}
                    <span className="typed-text">
                        <Typewriter
                            words={['Md. Zonieed Hossain', 'Software Engineer', 'SDE-II Golang Engineer']}
                            loop={0}
                            typeSpeed={80}
                            deleteSpeed={80}
                        />
                    </span>
                </h2>
                <div className="center-container">
                    <p className="mt-2 text-md text-highlight center-list">
                       <ul>
                           <li>Accomplished Software Engineer with over five years of experience in designing and
                                developing scalable software solutions and microservices.
                            </li>
                            <li>I am proficient in Golang, Python, MicroServices, GraphQL, and gRPC, with a strong
                                background in AWS, Kubernetes, and Docker.
                            </li>
                            <li>Adept at enhancing operational systems and user interfaces under critical conditions,
                                consistently delivering high-quality solutions on time.
                            </li>
                        </ul>
                </p>
                <SocialLinks/>
            </div>
        </div>
</section>
)
    ;
};

export default Home;
