import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import './index.css';

function App() {
    return (
        <div className="App">
            <Navbar />
            <Home />
            {/* Other components */}
        </div>
    );
}

export default App;
