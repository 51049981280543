import React from 'react';
import { FaFacebookF, FaTwitter, FaGithub, FaLinkedinIn, FaDribbble } from 'react-icons/fa';

const SocialLinks = () => {
    return (
        <div className="mt-4 flex justify-center space-x-4">
            <a href="https://facebook.com" className="social-link text-white hover:text-green-400">
                <FaFacebookF size="1.2rem" />
            </a>
            <a href="https://twitter.com" className="social-link text-white hover:text-green-400">
                <FaTwitter size="1.2rem" />
            </a>
            <a href="https://github.com" className="social-link text-white hover:text-green-400">
                <FaGithub size="1.2rem" />
            </a>
            <a href="https://linkedin.com" className="social-link text-white hover:text-green-400">
                <FaLinkedinIn size="1.2rem" />
            </a>
            <a href="https://dribbble.com" className="social-link text-white hover:text-green-400">
                <FaDribbble size="1.2rem" />
            </a>
        </div>
    );
};

export default SocialLinks;
