import React, { useState } from 'react';
import './Navbar.css'; // Include the CSS file for custom styling

const Navbar = () => {
    const [active, setActive] = useState('home');

    const handleClick = (section) => {
        setActive(section);
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <div className="navbar-logo">ZONIEED.</div>
                <ul className="navbar-links">
                    <li className="nav-item">
                        <a className={`nav-link ${active === 'home' ? 'active' : ''}`} href="#home"
                           onClick={() => handleClick('home')}>HOME</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${active === 'about' ? 'active' : ''}`} href="#about"
                           onClick={() => handleClick('about')}>ABOUT</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${active === 'resume' ? 'active' : ''}`} href="#resume"
                           onClick={() => handleClick('resume')}>RESUME</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${active === 'works' ? 'active' : ''}`} href="#works"
                           onClick={() => handleClick('works')}>WORKS</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${active === 'blog' ? 'active' : ''}`} href="#blog"
                           onClick={() => handleClick('blog')}>BLOG</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${active === 'contact' ? 'active' : ''}`} href="#contact"
                           onClick={() => handleClick('contact')}>CONTACT</a>
                    </li>
                </ul>
                <a href="#hireme" className="hireme-btn">HIRE ME</a>
            </div>
        </nav>
);
};

export default Navbar;
